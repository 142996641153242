<template>
  <div class="basicBox">
    <div class="pageName">
      <p>字体颜色</p>
      <el-color-picker v-model="notice.color"></el-color-picker>
      <el-button type="primary" @click="resetData('color')">重置</el-button>
    </div>
    <div class="pageName">
      <p>背景颜色</p>
      <el-color-picker v-model="notice.bgColor"></el-color-picker>
      <el-button type="primary" @click="resetData('bgColor')">重置</el-button>
    </div>
    <div class="pageName">
        <div class="itemMid">
            <img :src="notice.icon" alt="icon" class="imgBox" v-if="notice.icon"/>
            <div class="upload" @click="chooseImg">选择图片</div>
        </div>
        <div class="itemrright">
            <div class="tips"></div>
            <div class="clearImg" @click="clearImg">清除图片</div>
        </div>
    </div>
    <p class="tips" style="margin-left:122px;margin-bottom: 10px;">
        建议尺寸36px*36px
    </p>
    <div class="pageName">
        <p>公告内容</p>
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="notice.text"></el-input>
    </div>
    <!-- 选图标 -->
    <UploadSource
      v-if="chooseImgFlag"
      @changeStatus="changeStatus"
      :dialogVisible="chooseImgFlag"
      @getSource="getImgRes"
    ></UploadSource>
  </div>
</template>

<script>
import UploadSource from '@/components/uploadSource'
export default {
    props: ['notice'],
    components:{
        UploadSource
    },
    data() {
        return {
            chooseImgFlag:false
        }
    },
    created() {
    },
    methods: {
        resetData(attr){
            this.$emit('reset-data',{
                data:'notice',
                attr
            })
        },
        changeStatus(val) {
            this.chooseImgFlag = val
        },
        // 选择图片
        chooseImg() {
            this.chooseImgFlag = !this.chooseImgFlag
        },
        getImgRes(imglist) {
            this.notice.icon = imglist[0].path
            if (imglist.length > 1) {
                this.$message({
                message: '只能选取一张图，已自动截取第一张',
                type: 'error'
                })
            }
            this.chooseImgFlag = false
        },
        // 清除图片
        clearImg() {
            this.notice.icon = ''
        },
    },
}
</script>

<style lang="less" scoped>
.basicBox {
  padding: 30px;
  .tips {
    color: #9a9a9a;
  }
  .pageName {
    display: flex;
    margin-bottom: 20px;

    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
  .itemMid {
    position: relative;
    width: 82px;
    height: 82px;
    border: 1px dotted #333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 122px;
    .imgBox {
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin-bottom: 23px;
       object-fit: cover;
    }
    .upload {
      width: 100%;
      line-height: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      text-align: center;
    }
  }
  .itemrright{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
       margin-left: 20px;
  }
  .clearImg {
    color: #6d99ff;
    cursor: pointer;
    user-select: none;
  }
}
</style>
