<template>
    <div class="recommend">
        <div class="title">
            <div class="flex1">{{recommend.class_name}}</div>
            <div class="title_text">查看更多</div>
            <img :src="imgH+'rightArrow_1.png'">
        </div>
        <div class="goods">
            <div v-for="item in list" :key="item.id" class="goods_item">
                <img :src="item.goods_picture">
                <div class="goods_name">{{item.goods_name}}</div>
                <div class="goods_price">
                    <span style="font-size: 12px;">￥</span><span>{{item.goods_price}}</span>
                </div>
                <div class="goods_prime_cost">￥{{item.goods_price}}</div>
                <i class="el-icon-circle-plus"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { imgUrlHead } from '@/util/config.js'
export default {
    props:['recommend'],
    data() {
        return {
            imgH: imgUrlHead,
            list:[]
        }
    },
    watch:{
        'recommend.class_id'(val){
            this.getGooods(val)
        }
    },
    methods:{
        getGooods(val){
            this.$axios.post(this.$api.goods.list, {
                classify_id:val,
                page: 1,
                rows: 10
            })
            .then((res) => {
                if (res.code == 0) {
                    this.list = res.result.list
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .recommend{
        padding:10px 15px 2px;
        position: relative;
        z-index:100;
        .title{
            display: flex;
            align-items: center;
            height: 50px;
            .flex1{
                font-size: 18px;
                font-weight: bold;
            }
            .title_text{
                font-size: 14px;
                color: #999999;
                margin-right: 4px;
            }
            img{
                width: 10px;
                height: 10px;
            }
        }
        .goods{
            display: flex;
            overflow-x: auto;
            .goods_item{
                position: relative;
            }
            .goods_item + .goods_item{
                margin-left: 10px;
            }
            img{
                width: 100px;
                height: 100px;
                border-radius: 10px;
            }
            .goods_name{
                line-height: 30px;
                font-size: 14px;
            }
            .goods_price{
                color: #ff0000;
                font-size: 16px;
            }
            .goods_prime_cost{
                color: #999999;
                text-decoration: line-through;
                font-size: 12px;
            }
            .el-icon-circle-plus{
                color: #ff0000;
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: 24px;
            }
        }
    }
</style>
