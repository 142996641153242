<template>
  <div class="allGoodsBig">
    <div class="set_s_card">
      <div class="card_title">背景设置</div>
      <div class="tips">*仅显示在最底部，左侧仅为预览效果，分类内的商品全部展示</div>
      <div class="set_item">
        <div>背景颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.bgColor"></el-color-picker>
          <div class="blue-l" @click="returnbgColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>价格颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.priceColor"></el-color-picker>
          <div class="blue-l" @click="returnpriceColor">重置</div>
        </div>
      </div>
      <div class="set_item">
        <div>加购颜色</div>
        <div class="content">
          <el-color-picker v-model="controlDefault.carColor"></el-color-picker>
          <div class="blue-l" @click="returncarColor">重置</div>
        </div>
      </div>
    </div>

    <div class="set_s_card l">
      <div class="card_title">组件设置</div>
      <div class="set_item">
        <div>商品价格</div>
        <el-radio-group v-model="controlDefault.showPrice">
          <el-radio :label="0">显示</el-radio>
          <el-radio :label="1">不显示</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item flex_start">
        <div>加购物车</div>
        <el-radio-group v-model="controlDefault.carStyle">
          <el-radio :label="0">样式一</el-radio>
          <el-radio :label="1">样式二</el-radio>
          <el-radio :label="2">不显示</el-radio>
        </el-radio-group>
      </div>
      <div class="set_item">
        <div>显示全部</div>
        <el-radio-group v-model="controlDefault.showAll">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="set_i_card l">
      <div class="card_top" style="margin-bottom: 24px">
        <div class="card_title">分类列表</div>
        <div class="editArea">
          <el-tooltip effect="light" content="添加分类" placement="top-end">
            <img style="margin-right: 8px" @click="addClass" :src="imgH + 'add.png'" alt="" />
          </el-tooltip>
          <el-popover placement="bottom" trigger="click">
            <div class="showImage">
              <div class="image" v-for="(item, index) in controlDefault.list" :key="index">
                <div>{{ item.class_name }}</div>
                <div class="delMask" @click="deleteItem(index)"><i class="el-icon-delete"></i></div>
              </div>
            </div>
            <img slot="reference" :src="imgH + 'delete.png'" alt="" />
          </el-popover>
        </div>
      </div>
      <vuedraggable v-model="controlDefault.list" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }">
        <transition-group>
          <div class="set_item" v-for="(item, index) in controlDefault.list" :key="index">
            <div>第{{ index + 1 }}个分类</div>
            <div class="content">
              <el-input disabled v-model="item.class_name" placeholder="选择分类"></el-input>
              <div class="blue-l" @click="chooseClass(index)">选择</div>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>

    <!-- 商品分类弹框 -->
    <el-dialog title="选择分类" :visible.sync="showCategory" width="52%">
      <div>
        <ChooseCategory @getResult="getClass"></ChooseCategory>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import ChooseCategory from '@/views/addPages/chooseCategory/index.vue';
import { imgUrlHead } from '@/util/config.js';
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    vuedraggable,
    ChooseCategory,
  },
  data () {
    return {
      imgH: imgUrlHead + 'diyComponentsImg/',
      showCategory: false,
      index: -1,
    };
  },
  methods: {
    returnbgColor () {
      this.controlDefault.bgColor = null;
    },
    returnpriceColor () {
      this.controlDefault.priceColor = null;
    },
    returncarColor () {
      this.controlDefault.carColor = null;
    },
    addClass () {
      this.controlDefault.list.push({
        class_name: '选择分类',
        class_id: -2,
      });
    },
    deleteItem (index) {
      if (this.controlDefault.list.length <= 1) {
        this.$message.warning('请至少保留一个分类!');
        return;
      }
      this.controlDefault.list.splice(index, 1);
    },
    chooseClass (index) {
      this.index = index;
      this.showCategory = true;
    },
    getClass (id, name, picture) {
      this.controlDefault.list[this.index].class_name = name;
      this.controlDefault.list[this.index].picture = picture;
      this.controlDefault.list[this.index].class_id = id;
      this.showCategory = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(../../../publicStyle.less);
</style>
