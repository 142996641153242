<template>
  <div>
    <div class="searchCategory">
      <el-input v-model="categoryName"></el-input>
      <el-button type="primary" class="" @click="getCategory">选择</el-button>
    </div>
    <div v-for="(item, index) in categoryList" :key="index" class="categoryList">
      <div class="first" @click="item.show_child = !item.show_child">
        <div style="display: flex; align-items: center">
          <p class="showIcon">{{ item.show_child ? '-' : '+' }}</p>
          {{ item.name }}
        </div>
        <div>一级</div>
        <div @click="chooseCategory(item.name, item.id)" class="choose">选择</div>
      </div>
      <div class="second" v-show="item.show_child">
        <div v-for="(item1, index1) in item._child" :key="index1">
          <div>{{ item1.name }}</div>
          <div>二级</div>
          <div @click="chooseCategory(item.name, item1.id, item1.name)" class="choose">选择</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryName: '',
      categoryList: [],
      className: '',
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    // 查询分类信息
    getCategory() {
      this.$axios
        .post(this.$api.goods.goodsClass, {
          name: this.categoryName,
          is_show: 0,
        })
        .then(res => {
          if (res.code == 0) {
            this.categoryList = res.result;
            this.categoryList.map(item => {
              this.$set(item, 'show_child', false);
            });
          }
        });
    },
    // 选择分类
    chooseCategory(name, id, name1) {
      if (name1) {
        this.className = name1;
      } else {
        this.className = name;
      }
      this.$emit('getResult', id, this.className);
    },
  },
};
</script>

<style lang="less" scoped>
.searchCategory {
  display: flex;
  margin-bottom: 20px;
}
.categoryList {
  .first {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    height: 45px;
    .showIcon {
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      border: 1px solid #e4e4e4;
      margin-right: 30px;
      font-size: 22px;
    }
  }
  .second {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      height: 45px;
      padding-left: 100px;
    }
  }
}
.choose {
  color: #3370ff;
  cursor: pointer;
}
</style>
