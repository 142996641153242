<template>
  <div class="addGoodsBig" :style="{ background: AllGoods.bgColor }">
    <div>
      <div class="menuTop">
        <div v-show="AllGoods.showAll == 0" :class="['menu', active == -1 ? 'active' : '']" @click="getActive(-1, -1)">全部</div>
        <div v-for="(item, index) in AllGoods.list" :key="index" :class="['menu', active == index ? 'active' : '']" @click="getActive(index, item.class_id)">{{ item.class_name }}</div>
      </div>
      <div class="noneItem" v-if="goodsList.length == 0">该分类暂无商品</div>
      <div class="listBigBox" v-else @scroll="loadingItem" v-show="listFlag">
        <div class="listBox">
          <div class="itemBox" v-for="(item, index) in goodsList" :key="index">
            <div class="top">
              <img :src="item.goods_picture" alt="" />
            </div>
            <div class="mid">
              {{ item.goods_name }}
            </div>
            <div class="subTitle">
              {{ item.goods_subtitle }}
            </div>
            <div class="bot">
              <div class="price" v-show="AllGoods.showPrice == 0">
                <span :style="{ color: AllGoods.priceColor, fontSize: '16px' }"><span style="font-size: 12px">¥</span> {{ item.goods_price }}</span>
              </div>
              <div class="shopcar" v-show="AllGoods.carStyle != 2">
                <i v-show="AllGoods.carStyle == 1" :style="{ color: AllGoods.carColor }" class="iconfont icon-icon8"></i>
                <div v-show="AllGoods.carStyle == 0" class="addIcon" :style="{ background: AllGoods.carColor }">
                  <i style="color: white; font-size: 14px" class="el-icon-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    AllGoods: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      active: -2,
      activeId: -1,
      page: 1,
      rows: 10,
      goodsList: [],
      total: 0,
      getFlag: true,
      listFlag: true
    }
  },

  methods: {
    getActive(index, id) {
      if (index >= -1) {
        this.listFlag = true
      }
      this.active = index
      this.activeId = id
      this.goodsList = []
      this.page = 1
      this.getList()
    },
    getList(status) {
      let that = this
      if (this.active == -1) {
        this.$axios
          .post(this.$api.goods.list, {
            is_putaway: 2,
            page: that.page,
            rows: that.rows
          })
          .then((res) => {
            if (res.code == 0) {
              this.total = res.result.total_number
              if (status) {
                that.goodsList = res.result.list
              } else {
                that.goodsList.push(...res.result.list)
              }
            }
          })
      } else {
        this.$axios
          .post(this.$api.goods.list, {
            classify_id: this.activeId,
            page: that.page,
            rows: that.rows
          })
          .then((res) => {
            if (res.code == 0) {
              this.total = res.result.total_number
              if (status) {
                that.listFlag = true
                that.goodsList = res.result.list
              } else {
                that.goodsList.push(...res.result.list)
              }
            }
          })
      }
    },
    loadingItem(e) {
      const ele = e.srcElement ? e.srcElement : e.target
      // 监听滚动到div底部
      if (ele.scrollTop + ele.offsetHeight - ele.scrollHeight >= -1) {
        if (this.getFlag) {
          if (Math.ceil(this.total / this.rows) > this.page) {
            this.page++
            this.getFlag = false
            this.getList()
            setTimeout(() => {
              this.getFlag = true
            }, 1000)
          }
        }
      }
    }
  },
  watch: {
    'AllGoods.showAll': {
      handler(val) {
        if (val == 0) {
          this.active = -1
          this.getList(1)
        } else {
          this.active = this.active == -1 || this.active == -2 ? 0 : this.active
          if (this.AllGoods.list[0].class_name == '选择分类') {
            this.active = 0
            this.listFlag = false
          } else {
            this.activeId = this.AllGoods.list[0].class_id
            this.getList(1)
          }
        }
      },
      immediate: true
    },
    'AllGoods.list': {
      handler(val) {
        if (this.active != -1) {
          this.activeId = val[this.active].class_id
          this.page = 1
          this.goodsList = []
          this.getList()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.addGoodsBig {
  padding: 3px 0;
  & > div {
    margin: 0 13px;
  }
  .menuTop {
    display: flex;
    align-items: center;
    padding: 10px 0;
    overflow-x: auto;
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #fff;
  }
}
.active {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #333333 !important;
}
.menu {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
  margin-right: 10px;
  cursor: pointer;
  white-space: nowrap;
}
.listBox {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .itemBox {
    width: 47%;
    padding-bottom: 8px;
    background-color: #fff;
    // border: 1px solid #ccc;
    margin: 5px 0;
    border-radius: 8px;
    overflow: hidden;
    .top {
      width: 100%;
      height: 161px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
          width: 100%;
          height: 100%;
          border-radius: 10px;
      }
    }
    .mid {
      margin: 8px 0;
      height: 34px;
      line-height: 18px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      font-size: 14px;
      padding-left: 8px;
      font-weight: bold;
    }
    .subTitle {
      font-size: 12px;
      color: #c9c9c9;
      margin-bottom: 8px;
      padding-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .bot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 0 8px;
      .shopcar {
        cursor: pointer;
      }
    }
    .fz20 {
      font-size: 20px;
    }
  }
}
.noneItem {
  color: #ccc;
  text-align: center;
  margin-top: 40px;
  font-size: 14px;
}
.listBigBox {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.addIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-weight: bold;
  }
}
</style>
