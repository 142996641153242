<template>
  <div class="recommendControl">

    <div class="listBox">
      <vuedraggable
        v-model="controlDefault"
        v-bind="{
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost',
          scroll: true
        }"
      >
        <transition-group>
          <div class="listItem" v-for="(item, index) in controlDefault" :key="index">
            <div class="left">
              <div>
                <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''">&lt;</span>
                <span @click="moveClick(1, index, item)" :class="index == controlDefault.length - 1 ? 'fcccc' : ''">></span>
              </div>
              <div>第{{ index + 1 }}个分类</div>
            </div>
            <div class="mid">
              <el-input disabled v-model="item.class_name" placeholder="选择分类"></el-input>
              <el-button type="primary" @click="chooseClass(index)">选择</el-button>
            </div>
            <div class="right">
              <i v-if="index != 0" class="iconfont icon-guanbi" @click="deleteItem(index)"></i>
            </div>
          </div>
        </transition-group>
      </vuedraggable>
    </div>
    <div @click="addClass" class="addOne">新增一个</div>
    <!-- 商品分类弹框 -->
    <el-dialog title="选择分类" :visible.sync="showCategory" width="52%">
      <div>
        <ChooseCategory @getResult="getClass"></ChooseCategory>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import ChooseCategory from '../../addPages/chooseCategory/index'
export default {
  props: {
    controlDefault: {
      type: Object,
      default: () => []
    }
  },
  components: {
    vuedraggable,
    ChooseCategory
  },
  data() {
    return {
      showCategory: false,
      index: -1
    }
  },
    watch:{
        controlDefault(){
            this.updateData()
        }
    },
    methods: {
        updateData(){
            this.$emit('update-data', this.controlDefault)
        },
        // 点击移动banner图位置
        moveClick(status, index, item) {
        if (status == 0) {
            // 向上移
            if (index == 0) {
            this.$message({
                message: '已经在最前面了~',
                type: 'error'
            })
            return
            }
            this.controlDefault.splice(index, 1)
            this.controlDefault.splice(index - 1, 0, item)
        } else {
            // 向下移
            if (index == this.controlDefault.length - 1) {
            this.$message({
                message: '已经在最后面了~',
                type: 'error'
            })
            return
            }
            this.controlDefault.splice(index + 2, 0, item)
            this.controlDefault.splice(index, 1)
        }
        },
        addClass() {
        this.controlDefault.push({
            class_name: '选择分类',
            class_id: -2
        })
        },
        deleteItem(index) {
        this.controlDefault.splice(index, 1)
        },
        chooseClass(index) {
        this.index = index
        this.showCategory = true
        },
        getClass(id, name) {
        this.controlDefault[this.index].class_name = name
        this.controlDefault[this.index].class_id = id
        this.showCategory = false
        }
    }
}
</script>

<style lang="less" scoped>
.fcccc {
  color: #ccc;
  border: 1px solid #ccc !important;
  cursor: no-drop !important;
}
.recommendControl {
  width: 100%;
  padding: 30px;
  .listItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        display: block;
        border: 1px solid #333;
        font-weight: bold;
        cursor: pointer;
        margin-right: 20px;
      }
      span:first-child {
        transform: rotate(90deg);
      }
      span:last-child {
        transform: rotate(90deg);
        margin-top: 10px;
      }
    }
    .mid {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
    }
  }
  .addOne {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #3c76ff;
    border: 1px solid #3c76ff;
    cursor: pointer;
    margin-top: 20px;
  }
}
</style>
